export const validateRequiredFields = (data, requiredFields) => {
  for (const field of requiredFields) {
    if (
      !data.hasOwnProperty(field) ||
      data[field] === "" ||
      data[field] === null ||
      data[field] === undefined
    ) {
      return false;
    }
  }
  return true;
};

export const transformUnderscoreStringToTitle = (input) => {
  let words = input.split("_");

  let formattedWords = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return formattedWords;
};
