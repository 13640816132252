import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/AuthSlice";
import globalSlice from "../slices/globalSlice";
import chatReducer from "../slices/ChatSlice";
import casesReducer from "../slices/CasesSlice";
import configReducer from "../slices/ConfigSlice";
import requesterSlice from "../slices/RequesterSlice";
import userSlice from "../slices/UserSlice";
import departmentSlice from "../slices/DepartmentSlice";
import toastSlice from "../slices/ToastSlice";
import faqsSlice from "../slices/FAQSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    global: globalSlice.reducer,
    chat: chatReducer.reducer,
    cases: casesReducer.reducer,
    configs: configReducer.reducer,
    requester: requesterSlice.reducer,
    user: userSlice.reducer,
    departments: departmentSlice.reducer,
    toast: toastSlice.reducer,
    faqs: faqsSlice.reducer,
  },
});

export default store;
