import React, { useState } from "react";

const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} b`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} kb`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} mb`;
  }
};

const DragAndDropBox = ({ files, setFiles, isEditable }) => {
  const [error, setError] = useState(false);
  const maxFileSize = 25 * 1024 * 1024; // 25MB
  
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    const { validFiles, oversizedFiles } = validateFiles(droppedFiles);

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);

    if (oversizedFiles.length > 0) {
      setError(`${oversizedFiles.length} file(s) were not added because they exceed the 25MB limit.`);
    } else {
      setError(""); 
    }
  };

  const handleFileSelect = (e) => {
    
    const selectedFiles = Array.from(e.target.files);

    const { validFiles, oversizedFiles } = validateFiles(selectedFiles);

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);

    if (oversizedFiles.length > 0) {
      setError(`${oversizedFiles.length} file(s) were not added because they exceed the 25MB limit.`);
    } else {
      setError(""); 
    }
  };

  const validateFiles = (fileList) => {
    const validFiles = [];
    const oversizedFiles = [];

    fileList.forEach((file) => {
      if (file.size <= maxFileSize) {
        validFiles.push(file);
      } else {
        oversizedFiles.push(file);
      }
    });

    return { validFiles, oversizedFiles };
  };

  const handleRemoveFile = (fileName) => {
    // if (!isEditable) {
    //   return false;
    // }
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  return (
    <div
      className="drag-drop-box"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        type="file"
        id="file-input"
        multiple
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />

      <div className={`drop-area ${error ? "error" : ""}`}>
        {}
        {files.length > 0 && (
          <div className="file-list">
            {files.map((file) => (
              <div key={file.name} className="file-item shadow-sm">
                <span className="file-name">{file.name}</span>
                <span className="file-size">{formatFileSize(file.size)}</span>
                <button
                  onClick={() => handleRemoveFile(file.name)}
                  className="remove-btn"
                >
                  x
                </button>
              </div>
            ))}
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        <div className="drag-text">
          <span>Drag and drop files here or </span>
          <label htmlFor="file-input">
            <span className="browse-link">browse</span>
          </label>
          .
        </div>
      </div>
    </div>
  );
};

export default DragAndDropBox;
