import React, { useEffect, useState } from "react";
import { getUserDetailsByUserPrincipalName } from "../../slices/api/UsersApi";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { grey } from "@mui/material/colors";

const UserProfileView = () => {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  const [loadingProfileResponse, setLoadingProfileResponse] =
    useState("Loading...");
  const authUser = useSelector((state) => state.auth);
  const departmentsState = useSelector((state) => state.departments);

  useEffect(() => {
    const url = window.location.href;
    const email = url.split("/").pop();
    console.log("Email address:", email);

    async function fetchuserDetaisl() {
      try {
        const userDetails = await getUserDetailsByUserPrincipalName(email);
        setUserInfo(userDetails.data);
      } catch (e) {
        setLoadingProfileResponse(
          "An error occured while trying to get details abou this profile"
        );
      }
    }

    fetchuserDetaisl();
  }, []);

  useEffect(() => {
    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = true; //to get all department

      subscribeToDepartments(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );
    }
  }, [dispatch, authUser]);

  const getDepartmentNameById = (departmentId) => {
    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.find(
        (category) => category.id == departmentId
      );
      return filteredDepartments && filteredDepartments.name
        ? filteredDepartments.name
        : "Unknown.";
    }

    return "Loading..";
  };

  return (
    <div id="user_account">
      <div className="header-part">
        <h2>Account</h2>
      </div>
      {Object.keys(userInfo).length > 0 ? (
        <>
          <div className="name-and-profile-pic">
            <p style={{ overflowWrap: "anywhere" }}>
              {userInfo.profilePicture &&
              userInfo.profilePicture != "" &&
              userInfo.profilePicture.length > 5 != "" ? (
                <img
                  src={`data:image/jpeg;base64,${userInfo.profilePicture}`}
                  className="profile-pic"
                />
              ) : (
                <AccountCircleIcon sx={{ fontSize: 130, color: "#b3b3b3" }} />
              )}
            </p>
            <div className="name-side">
              <h2>
                {userInfo.givenName +
                  " " +
                  userInfo.surname +
                  " (" +
                  userInfo.displayName +
                  ")"}
              </h2>
              <span>{userInfo.jobTitle}</span>
              <span>
                <a href={"mailto:" + userInfo.mail}>{userInfo.mail}</a> 
                { userInfo.mobilePhone &&
                  "| " +
                  <a href={"tel:" + userInfo.mobilePhone}>
                    {userInfo.mobilePhone}
                  </a>
                }
              </span>
            </div>
          </div>

          <div className="profile-details">
            <h4>More Details</h4>
            <div className="card-content">
              <div>
                Department:{" "}
                <span>{getDepartmentNameById(userInfo.department)}</span>
              </div>
              <div>
                Delivery Site: <span>{userInfo.officeLocation}</span>
              </div>
              <div>
                Office Phone: <span>{userInfo.businessPhone}</span>
              </div>
            </div>
            <div>
              Permissions:
              <div className="checkbox-container" style={{ paddingLeft: "2%" }}>
                <div>
                  <label htmlFor="super_admin_chk">
                    Is Super Admin : {!!userInfo.isSuperAdmin ? "Yes" : "No"}
                  </label>
                </div>
                <div>
                  <label htmlFor="admin_chk">
                    Is Admin : {!!userInfo.isAdmin ? "Yes" : "No"}
                  </label>
                </div>
                <div>
                  <label htmlFor="technician_chk">
                    Is Technician/Specialist:
                    {!!userInfo.isSpecialist ? "Yes" : "No"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{loadingProfileResponse}</>
      )}
    </div>
  );
};

export default UserProfileView;
