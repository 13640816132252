import {
  createBrowserRouter,
  Route,
  redirect,
  createRoutesFromElements,
} from "react-router-dom";
import HomePage from "../pages/Home";
import Login from "../pages/Login";
import Cases from "../pages/cases/cases";
import CasesList from "../components/cases/CasesList";
import CreateCase from "../components/cases/CreateCase";
import SingleCaseView from "../components/cases/SingleCaseView";
import Survey from "../pages/survey/survey";
import Account from "../pages/account/Account";
import ViewFAQs from "../pages/faqs/faqs";
import SingleFAQ from "../components/faqs/SingleFAQ";
import ListFAQs from "../components/faqs/ListFAQs";
import UserProfileView from "../pages/account/UserProfileView";

const loader = async () => {
  const isAuthinticated = localStorage.getItem("isAuthinticated");
  if (!isAuthinticated) {
    return redirect("/");
  }
  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route key="login" path="/" element={<Login />} />,
    <Route key="survey" path="survey" element={<Survey />} />,

    <Route
      key="home"
      path="/"
      element={<HomePage />}
      children={[
        <Route
          key="cases"
          path="cases"
          element={<Cases />}
          children={[
            <Route
              key="cases_list"
              index
              path=""
              element={<CasesList />}
              loader={loader}
            />,
            <Route
              key="create_case"
              path="create_case"
              element={<CreateCase />}
              loader={loader}
            />,
            <Route
              key="single_case"
              path="single_case/:caseId"
              element={<SingleCaseView />}
              loader={loader}
            />,
          ]}
        />,
        <Route
          key="profile"
          path="profile"
          element={<Account />}
          loader={loader}
        />,
        <Route
          key="user_profile_view"
          path="user_profile_view/:id"
          element={<UserProfileView />}
          loader={loader}
        />,
        <Route
          path="faqs"
          element={<ViewFAQs />}
          children={[
            <Route
              key="faqs"
              index
              path=""
              element={<ListFAQs />}
              loader={loader}
            />,
            <Route key="view" path="view/:id" element={<SingleFAQ />} />,
          ]}
        />,
      ]}
    />,
  ])
);

export default router;
