import { createSlice } from "@reduxjs/toolkit";
import { subscribeToCases as subscribeToCasesAPI } from "./api/casesApi";

const initialState = {
  casesList: [],
  activeCase: null,
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    setCases: (state, action) => {
      state.casesList = action.payload;
    },
    setActiveCase: (state, action) => {
      console.log("Setting case....", action.payload)
      state.activeCase = action.payload;
    },
  },
});

export const { setCases, setActiveCase } = casesSlice.actions;

export default casesSlice;
