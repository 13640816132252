import { useState, useEffect } from "react";
import { getUserByPrincipalFromAd } from "../slices/api/UsersApi";

const CampusName = ({ caseRequester }) => {
  const [campusName, setCampusName] = useState("");

  useEffect(() => {
    const fetchCampusName = async () => {
      const { data } = await getUserByPrincipalFromAd(caseRequester);
      //   console.log("user", data);
      setCampusName(data.city || "Unknown");
    };

    fetchCampusName();
  }, [caseRequester]);

  return <>{campusName}</>;
};

export default CampusName;
