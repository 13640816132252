const allowedIncomingMessages = {
  TYPES: {
    TEXT: "text",
    BUTTON: "button",
    DOCUMENT: "document",
    IMAGE: "image",
    INTERACTIVE_REPLY: "interactive",
  },
  MIME_TYPES: {
    PDF: "application/pdf",
    IMAGE_JPEG: "image/jpeg",
    IMAGE_PNG: "image/png",
  },
};

const endpoints = {
  EMAIL_SERVICE_ENDPOINT:
    "https://wsfu2dtn6dg52ztqh6ud6xjlda0jholp.lambda-url.af-south-1.on.aws/",
};

const channels = {
  WHATSAPP: "WHATSAPP", //ToDo make it WhatsApp
  WEB_CHAT: "WEB_CHAT", //ToDo make it Web Chat
  WEB_PORTAL: "Web Portal",
};

const chatStages = {
  INITIAL: "Initial",
  CHOOSING_CATEGORY: "CHOOSING_CATEGORY",
  CHAT_OPENED: "CHAT_OPENED",
  CASE_OPENED: "CASE_OPENED",
  CLOSED: "CLOSED",
  ARCHIVED: "ARCHIVED",
  BLOCKED: "BLOCKED",
};

const messageSender = {
  AGENT: "AGENT",
  CLIENT: "CLIENT",
};

const createGreetingMessage = () => {
  const salutation = "Good Day";
  return `*${salutation}!* I am WSU Assistant, your friendly online support. I am able to help you with the following options😊`;
};

const createInitialResponse = async (automatedResponsesQuerySnapshot) => {
  let automatedResultsList =
    "*Make a selection* from below by choosing and replying with number.\n\n";

  let lineNumber = 1;
  automatedResponsesQuerySnapshot.forEach((doc) => {
    automatedResultsList +=
      lineNumber + ". " + doc.data().Label + " " + doc.data().Emoji + "\n";
    lineNumber++;
  });

  automatedResultsList += lineNumber + ". Other/General 🔘";

  return automatedResultsList;
};

const messages = {
  GREETING: createGreetingMessage(),
  INVALID_CHOICE: "*Invalid response*. Please reply with the correct response.",
  REDIRECT_TO_QUEUE_MESSAGE:
    "*Dear user*, we are redirecting you to a Customer Agent. Please stay online as our dedicated agent will be with you shortly. Your patience is greatly appreciated.",
  OTHER: "Other/General",
  OTHER_ID_PREFIX: "Other_",
};

const typeOfService = {
  CHATS: "chats",
  CASES: "cases",
};

const whatsapp = {
  WHATSAPP_END_POINT:
    "https://graph.facebook.com/v18.0/104770505677441/messages",
  WHATSAPP_TOKEN:
    "EAAFnkdZBjgCABO8vkJ0CUbZCMMIjWkocCpChKpP9EixL9FjURPqySvenqvavHS2UKuXFLL9VLIUPJthjUVSpWothosGEtwrZAnLDqj6EQ3FlAaAXKinsmQz5ALHUoycu1XjyMcAXhm0uZCxa81Ug6mZB00PZAzQnXnPvbVAU9Cl9QbymM4QmUy15ZANAfCXtj1LzsfGeZC0TIMWIUZANU0xHa0l4yZBPSXGL04my4ZD",
};

const sourceEmailList = {
  NO_REPLY: "noreply@servicedesk.myplusplus.com",
  SUPPORT: "support@servicedesk.myplusplus.com",
};

const priorities = {
  HIGH: {
    Label: "HIGH",
    Score: 2,
  },
  MEDIUM: {
    Label: "Medium",
    Score: 2,
  },
};

const caseStatuses = {
  OPEN: "open",
  CLOSED: "closed",
  ARCHIVED: "archived",
  ONHOLD: "on-hold",
  CANCELLED: "cancelled",
};

const departmentsStatuses = {
  DISABLED: "disabled",
  ACTIVE: "active",
};

const auditType = {
  DEPARTMENT_ESCALATION: "departmentEscalation",
  DEPARTMENT_STATUS_UPDATE: "departmentStatusUpdate",
  USER_TYPE_UPDATE: "userTypeUpdate",
  CATEGORY_UPDATE: "categoryUpdate",
};

const STORAGE_BUCKET_URL =
  "https://storage.googleapis.com/my-service-desk-a8855.appspot.com";

const Constants = {
  caseStatuses,
  priorities,
  whatsapp: whatsapp,
  allowedIncomingMessages: allowedIncomingMessages,
  messages: messages,
  chatStages: chatStages,
  createInitialResponse: createInitialResponse,
  messageSender: messageSender,
  channels: channels,
  typeOfService: typeOfService,
  endpoints: endpoints,
  sourceEmailList,
  departmentsStatuses,
  auditType,
  STORAGE_BUCKET_URL,
};

export default Constants;
