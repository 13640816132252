import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserDetailsByUserPrincipalName } from "../../slices/api/UsersApi";

const NamesOfAssignees = ({ assignees }) => {
  const [namesAsProfileLinks, setNamesAsProfileLinks] = useState([]);

  useEffect(() => {
    const fetchAssigneesProfiles = async () => {
      try {
        const assigneesProfiles = await Promise.all(
          assignees.map(async (assignee) => {
            try {
              const profileData = await getUserDetailsByUserPrincipalName(
                assignee
              );
              return profileData;
            } catch (error) {
              if (
                error.message ===
                "User not existing. Newly added users take a couple of hours to be available on Service Desk."
              ) {
                return { id: "notFound", data: null };
              } else {
                throw error;
              }
            }
          })
        );
        // Filter out null profiles and update state
        const validProfiles = assigneesProfiles.filter(
          (profile) => profile.id !== "notFound"
        );
        setNamesAsProfileLinks(validProfiles);
      } catch (error) {
        console.error("Error fetching assignees profiles:", error);
      }
    };

    fetchAssigneesProfiles();
  }, [assignees]);

  const handleViewAssigneeProfile = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      {namesAsProfileLinks.length > 0 ? (
        namesAsProfileLinks.map((profile, index) => {
          if (profile.status !== "notFound") {
            const isLastProfile = index === namesAsProfileLinks.length - 1;

            return (
              <div key={index}>
                <Link
                  to={`/user_profile_view/${profile.id}`}
                  onClick={handleViewAssigneeProfile}
                >
                  <u>{profile.data.displayName}</u>
                </Link>
                {!isLastProfile && ", "}
              </div>
            );
          }

          return <div key={index}>Error 404</div>;
        })
      ) : (
        <>...</>
      )}
    </>
  );
};

export default NamesOfAssignees;
