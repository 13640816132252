import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NameResolver from "../../common/NameResolver";
import { getDepartmentById } from "../../../slices/api/departmentsApi";
import CampusName from "../../CampusName";

const DetailsTab = () => {
  const [departmentName, setDepartmentName] = useState("Loading...");
  const dispatch = useDispatch();
  const { activeCase } = useSelector((state) => state.cases);
  const authUser = useSelector((state) => state.auth);

  // Fetch department name when department ID changes
  useEffect(() => {
    const fetchDepartmentName = async () => {
      if (activeCase?.department) {
        try {
          const department = await getDepartmentById(activeCase.department);
          setDepartmentName(department?.name || "Not found");
        } catch (error) {
          setDepartmentName("Error loading department");
        }
      } else {
        setDepartmentName("No department assigned");
      }
    };
    fetchDepartmentName();
  }, [activeCase?.department]);

  const resolveCategoryName = (categoryId) => {
    // Placeholder logic; replace with actual implementation if available
    return categoryId ? `${categoryId}` : "Not found";
  };

  // Subscribe to departments if needed, depending on authUser and conditions
  useEffect(() => {
    if (authUser) {
      const isSuperAdmin = authUser.user?.userInfo?.isSuperAdmin;
      // Uncomment if you need subscription logic
      // subscribeToDepartments(dispatch, authUser.user.userInfo.department, isSuperAdmin);
    }
  }, [dispatch, authUser]);

  // Reusable Date Formatter
  const formatDate = (date) => moment.utc(date).add(0, "hours").format("MMM DD, YYYY HH:mm");

  return (
    <div className="details-tab">
      <h5>Request details</h5>
      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Request type</td>
                  <td>Normal</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{activeCase?.status || "Unknown"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Impact</td>
                  <td className="impact-name">{activeCase?.impact || "None"}</td>
                </tr>
                <tr>
                  <td>Priority</td>
                  <td>{activeCase?.priority || "Low"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Requester Name</td>
                  <td className="global-name">
                    <NameResolver email={activeCase?.caseRequester} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Campus</td>
                  <td className="global-name">
                    <CampusName caseRequester={activeCase.caseRequester} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Department</td>
                  <td>
                    {activeCase?.department ? (
                      <>
                        {departmentName}
                        {activeCase?.category && activeCase?.category.length > 0 && ` / ${resolveCategoryName(activeCase.category)}`}
                      </>
                    ) : (
                      "Loading..."
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Assignee</td>
                  <td>
                    {activeCase?.assignedTo?.length > 0 ? (
                      activeCase.assignedTo.map((assignee) => (
                        <NameResolver key={assignee} email={assignee} />
                      ))
                    ) : (
                      "Not assigned yet."
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Date Created</td>
                  <td>{formatDate(activeCase?.dateCreated)}</td>
                </tr>
                <tr>
                  <td>Date Updated</td>
                  <td>{formatDate(activeCase?.dateUpdated)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Reported By</td>
                  <td className="global-name">
                    <NameResolver email={activeCase?.reportedBy} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <h5>Description</h5>
        <div
          dangerouslySetInnerHTML={{ __html: activeCase?.caseDescription || "No description provided." }}
        />
      </div>

      <div className="details-container">
        <h5>Main Attachments</h5>
        {activeCase?.attachments?.length > 0 ? (
          activeCase.attachments.map((attachment) => (
            <a
              key={attachment.messageId}
              href={attachment.attachmentUrl}
              className="file-item shadow-sm"
              download
            >
              {attachment.attachmentUrl}
            </a>
          ))
        ) : (
          "No attachments."
        )}
      </div>
    </div>
  );
};

export default DetailsTab;
