import React, { useEffect, useState } from "react";
import MyEditor from "../../Editor";
import DragAndDrop from "../../DragAndDropBox";
import { setActiveCase } from "../../../slices/CasesSlice";
import { updateCaseMasterData as updateCaseMasterDataApi } from "../../../slices/api/casesApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { fetchConfigsAsync } from "../../../slices/ConfigSlice";
import StorageService from "../../../services/storageService";
import Constants from "../../../constants/Constants";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import { subscribeToDepartments } from "../../../slices/api/departmentsApi";
import { sendEmailApi } from "../../../services/commonService";

const ResolutionTab = () => {
  const storageService = new StorageService();
  const [files, setFiles] = useState([]);
  const [resolutionMessage, setResolutionMessage] = useState("");
  const [textContent, setTextContent] = useState("");
  const configsState = useSelector((state) => state.configs);
  const [caseStatuses, setCaseStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const activeCase = useSelector((state) => state.cases.activeCase);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const departmentsState = useSelector((state) => state.departments);
  const authUser = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchConfigsAsync(dispatch);
    subscribeToDepartments(
      dispatch,
      authUser.user.userInfo.isAdmin,
      authUser.user.userInfo.department
    );
  }, [dispatch, authUser]);

  const getDepartmentAdminsEmails = (departmentId) => {
    const departmentDetails =
      departmentsState.departmentsList[
        departmentsState.departmentsList.findIndex(
          (department) => department.id == departmentId
        )
      ];

    return Object.keys(departmentDetails.admins);
  };

  const getDepartmentNameById = () => {
    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.find(
        (category) =>
          category.parentCategory == "main" &&
          category.id == activeCase.department
      );
      return filteredDepartments.name;
    }

    return "No found.";
  };

  const submitResolution = async (event) => {
    event.stopPropagation();

    if (resolutionMessage == "" || resolutionMessage.length == 0) {
      dispatch(setToastMessage("Please enter the resolution message."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));

      return false;
    }

    if (!selectedStatus) {
      dispatch(setToastMessage("Please select resolution status."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));

      return false;
    }

    setIsSubmitting(true);
    try {
      let payload = {
        status: selectedStatus.value,
        dateUpdated: new Date().toString(),
        resolutionDoneBy: authUser.user.userInfo.mail,
        resolutionMessage,
      };

      let resolutionFiles = [];

      for (var i = 0; i < files.length; i++) {
        try {
          const uploadFileResult = await storageService.uploadFile(
            files[i],
            Constants.typeOfService.CASES
          );

          resolutionFiles.push({
            attachmentUrl: uploadFileResult,
            dateUploaded: new Date().toString(),
          });
        } catch (error) {
          alert("Error occurred while uploading");
          console.log(error);
          return;
        }
      }

      if (resolutionFiles.length > 0) {
        payload.resolutionFiles = resolutionFiles;
      }

      await updateCaseMasterDataApi(activeCase.id, payload);

      try {
        //start sending resolution to client
        let templateDataForClient = {
          userName: "User",
          caseNumber: activeCase.caseNumber,
          caseSummary: activeCase.caseSummary,
          caseResolution: textContent,
          caseDepartment: getDepartmentNameById(),
          caseStatus: selectedStatus.value,
          caseLink: `${window.location.protocol}:${window.location.port}/${window.location.hostname}:${window.location.port}/cases/single_case/${activeCase.id}`,
        };

        const emailPayloadForClient = {
          recipientEmailAddresses: [activeCase.caseRequester],
          templateData: templateDataForClient,
          sourceEmail: Constants.sourceEmailList.NO_REPLY,
          templateName: "case_closure_confirmation",
        };

        if (emailPayloadForClient.recipientEmailAddresses.length > 0) {
          try {
            await sendEmailApi(emailPayloadForClient);
          } catch (e) {
            console.log("Status - 500", e);
          }
        }

        //end sending resolution to client

        //start sending resolution to assignee
        let templateDataForAssignees = {
          assigneeName: "User",
          userName: "case reporter",
          caseNumber: activeCase.caseNumber,
          caseSummary: activeCase.caseSummary,
          caseResolution: textContent,
          caseDepartment: getDepartmentNameById(),
          caseStatus: selectedStatus.value,
          caseLink: `${window.location.protocol}:${window.location.port}/${window.location.hostname}:${window.location.port}/cases/single_case/${activeCase.id}`,
        };

        const emailPayloadForAssignees = {
          recipientEmailAddresses: activeCase.assignedTo,
          templateData: templateDataForAssignees,
          sourceEmail: Constants.sourceEmailList.NO_REPLY,
          templateName: "case_resolution_assignee",
        };

        if (emailPayloadForAssignees.recipientEmailAddresses.length > 0) {
          try {
            await sendEmailApi(emailPayloadForAssignees);
          } catch (e) {
            console.log("Status - 500", e);
          }
        }
        //end sending resolution to assignee

        //start sending resolution to admins
        const adminsEmail = getDepartmentAdminsEmails(activeCase.department);

        let templateDataForAdmins = {
          adminName: "Admin",
          userName: authUser.user.userInfo.displayName,
          caseNumber: activeCase.caseNumber,
          caseSummary: activeCase.caseSummary,
          caseResolution: textContent,
          caseDepartment: getDepartmentNameById(),
          caseStatus: selectedStatus.value,
          impactType: activeCase.impact,
          caseLink: `${window.location.protocol}:${window.location.port}/${window.location.hostname}:${window.location.port}/cases/single_case/${activeCase.id}`,
        };

        const emailPayloadForAdmins = {
          recipientEmailAddresses: adminsEmail,
          templateData: templateDataForAdmins,
          sourceEmail: Constants.sourceEmailList.NO_REPLY,
          templateName: "case_resolution_admin",
        };

        if (emailPayloadForAdmins.recipientEmailAddresses.length > 0) {
          try {
            await sendEmailApi(emailPayloadForAdmins);
          } catch (e) {
            console.log("Status - 500", e);
          }
        }
        //end sending resolution to admins
      } catch (e) {
        console.log(e);
      }

      dispatch(setToastMessage("Successfuly closed the case."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));

      const timeoutId = setTimeout(() => {
        dispatch(setActiveCase(null));
        navigate(`/cases`);
      }, 3400);
      return () => clearTimeout(timeoutId);
    } catch (error) {
      console.log("an error occurred: ", error);
      dispatch(
        setToastMessage("An error occurred while trying to update the case.")
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setIsSubmitting(false);
    }
  };

  const handleStatusChange = (event, selected) => {
    setSelectedStatus(selected);
  };

  useEffect(() => {
    if (configsState && configsState.caseStatuses.length > 0) {
      setCaseStatuses(
        configsState.caseStatuses.filter((status) => status.value != "open")
      );
    }
  }, [configsState]);

  return (
    <div className="resolution-tab">
      {activeCase.status !== Constants.caseStatuses.CLOSED && (
        <p className="alert alert-warning">
          Please add resolution before you close the case
        </p>
      )}
      <h5>Resolution</h5>
      {activeCase.status !== Constants.caseStatuses.CLOSED ? (
        <form action="" method="post">
          <MyEditor
            comment={resolutionMessage}
            setComment={setResolutionMessage}
            setTextContent={setTextContent}
          />

          <h5 className="attachments-header">Attachments</h5>
          <DragAndDrop files={files} setFiles={setFiles} />

          <div className="row mt-4">
            <div className="col-4">
              <label htmlFor="">Status</label>
              <Autocomplete
                value={selectedStatus}
                onChange={handleStatusChange}
                options={caseStatuses}
                getOptionLabel={(caseStatus) => caseStatus.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="-- Select Status --"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>

          <div className="btns-container">
            <button
              type="button"
              className="main-btn"
              disabled={isSubmitting}
              onClick={submitResolution}
            >
              {isSubmitting ? "Updating..." : "Save"}
            </button>
            {!isSubmitting ? (
              <button type="button" className="no-border-btn">
                Cancel
              </button>
            ) : null}
          </div>
        </form>
      ) : (
        <div className="resolution-results">
          <div
            dangerouslySetInnerHTML={{ __html: activeCase.resolutionMessage }}
          ></div>

          <div>
            <h5>Resolution attachments</h5>
            {activeCase.resolutionFiles?.map((file, index) => (
              <a
                key={index}
                href={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CASES}/${file.attachmentUrl}`}
                download
                className="file-download shadow"
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.attachmentUrl}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResolutionTab;
