import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { logOut } from "../actions/auth/authActions";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutAsync } from "../slices/AuthSlice";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WSULogo from "../assets/img/wsu-white.png";

const TopNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.user);

  const handleLogOut = async () => {
    await dispatch(logoutAsync());
    navigate("/");
  };

  const handleProfileViewClick = async (event) => {
    navigate("/profile");
  };

  return (
    <div>
      <div className="top-nav">
        <div className="brand-part">
          <Link to="/cases">
            <img src={WSULogo} alt="" />
          </Link>
          <h4>
            MyWSU <span className="portal">Client</span>
          </h4>
        </div>
        <div className="menu-list">
          <Link to="/cases" className="link">Home</Link>
          <Link to="/faqs" className="link">FAQs</Link>
          <button className="profile-btn" onClick={handleProfileViewClick}>
            <AccountCircleIcon /> {userInfo.user.displayName}
          </button>
          <button type="button" onClick={handleLogOut} className="logout-btn">
            <LogoutIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
