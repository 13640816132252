import React from "react";
import CasesTable from "../../components/cases/CasesTable";
import { useNavigate } from "react-router-dom";

const CasesList = () => {
  const navigate = useNavigate();

  const onHandlePageChange = (pageName) => {
    navigate("" + pageName);
  };

  return (
    <>
      <div id="client_page">
        <div className="header-part">
          <h4 className="page-title">Client Portal</h4>
          <button
            type="button"
            className="main-btn"
            onClick={() => onHandlePageChange("create_case")}
          >
            New Case
          </button>
        </div>
        <CasesTable />
      </div>
    </>
  );
};

export default CasesList;
