import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";

const Account = () => {
  const { user, userInfo } = useSelector((state) => state.auth.user);
  const departmentsState = useSelector((state) => state.departments); 
  const dispatch = useDispatch(); 
  const [loadingProfileResponse, setLoadingProfileResponse] =
    useState("Loading...");
  const authUser = useSelector((state) => state.auth); 

  useEffect(() => {
    console.log(userInfo, "   userInfo");
  }, []);

  useEffect(() => {
    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = true; //to get all department

      subscribeToDepartments(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );
    }
  }, [dispatch, authUser]);

  const goToManageAccount = () => {
    window.open("https://account.microsoft.com/profile/", "_blank");
  };

  const getDepartmentNameById = (departmentId) => {
    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.find(
        (category) => category.id == departmentId
      );
      return filteredDepartments && filteredDepartments.name
        ? filteredDepartments.name
        : "Unknown.";
    }

    return "Loading..";
  };

  return (
    <div id="user_account">
      <div className="header-part">
        <h2>Account</h2>
        <button type="button" className="main-btn" onClick={goToManageAccount}>
          Update Profile
        </button>
      </div>
      <div className="name-and-profile-pic">
        <p style={{ overflowWrap: "anywhere" }}>
          {userInfo.profilePicture &&
          userInfo.profilePicture != "" &&
          userInfo.profilePicture.length > 5 ? (
            <img
              src={`data:image/jpeg;base64,${userInfo.profilePicture}`}
              className="profile-pic"
            />
          ) : (
            <AccountCircleIcon sx={{ fontSize: 130, color: "#b3b3b3" }} />
          )}
        </p>
        <div className="name-side">
          <h2>
            {userInfo.givenName +
              " " +
              userInfo.surname +
              " (" +
              userInfo.displayName +
              ")"}
          </h2>
          <span>{userInfo.jobTitle}</span>
          <span>
            <a href={"mailto:" + userInfo.mail}>{userInfo.mail}</a> |{" "}
            <a href={"tel:" + userInfo.mobilePhone}>{userInfo.mobilePhone}</a>
          </span>
        </div>
      </div>

      <div className="profile-details">
        <h4>More Details</h4>
        <div className="card-content">
          <div>
            Department:{" "}
            <span>{getDepartmentNameById(userInfo.department)}</span>
          </div>
          <div>
            Delivery Site: <span>{userInfo.officeLocation}</span>
          </div>
          <div>
            Office Phone: <span>{userInfo.businessPhone}</span>
          </div>

          <div>
            Permissions:
            <div className="checkbox-container" style={{ paddingLeft: "2%" }}>
              <div>
                <label htmlFor="super_admin_chk">
                  Super Admin : {!!userInfo.isSuperAdmin ? "Yes" : "No"}
                </label>
              </div>
              <div>
                <label htmlFor="admin_chk">
                  Department Admin : {!!userInfo.isAdmin ? "Yes" : "No"}
                </label>
              </div>
              <div>
                <label htmlFor="technician_chk">
                  Help Desk Officer:
                  {!!userInfo.isSpecialist ? "Yes" : "No"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
