import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setOpenFAQ } from "../../slices/FAQSlice";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { fetchFAQById } from "../../slices/api/faqApi";
import moment from "moment";

const SingleFAQ = () => {
  const openFAQ = useSelector((state) => state.faqs.openFAQ);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleGoBack = () => {
    dispatch(setOpenFAQ({}));
    navigate("/faqs");
  };

  useEffect(() => {
    async function fetchData(id) {
      const faq = await fetchFAQById(id);
      dispatch(setOpenFAQ(faq));
    }

    if (id && Object.keys(openFAQ).length == 0) {
      fetchData(id);
    }
  }, [id]);

  return (
    <>
      <h4>FAQ</h4>
      <button type="button" className="back-btn" onClick={handleGoBack}>
        <ChevronLeftIcon /> Back
      </button>
      <p>
        <b>{openFAQ.question}</b>
      </p>
      <div
        className="faq-content"
        dangerouslySetInnerHTML={{ __html: openFAQ.answer }}
      ></div>
      <br></br>
      <p>
        <b>Created by</b>: {openFAQ.createdByEmail}
      </p>
      <p>
        <b>Date Updated</b>:{" "}
        {moment
          .utc(new Date(openFAQ.dateUpdated), "MM/DD/YYYY h:mm A")
          .format("MMM DD, YYYY HH:mm")}
      </p>
    </>
  );
};

export default SingleFAQ;
